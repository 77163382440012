/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { useContext, useEffect, useState, useRef } from "react"
import { Disclosure, RadioGroup, Tab } from "@headlessui/react"
import { StarIcon } from "@heroicons/react/solid"
import {
  getSingleProduct,
  sessToken,
  trackVisits,
} from "../helpers/DrupalHelper"
import { HeartIcon, MinusSmIcon, PlusSmIcon } from "@heroicons/react/outline"
import Layout from "../components/Layout/Pages"
import { Link, navigate } from "gatsby"
import ProductImageBox from "../components/ProductImageBox"
import MetaDelivery from "../components/Elements/MetaDelivery"
import AssetsCard from "../components/Cards/AssestCard"
import RelatedProduct from "../components/RelatedProduct"
import Separator from "../components/Elements/Separator"
import CircleAvatar from "../components/Elements/CircleAvatar"
import Button from "../components/Elements/Button"
import ProductAssetListing from "../components/ProductAssetListing"
import { useMediaQuery } from "react-responsive"
import MetaBox from "../components/Elements/MetaBox"
import StateContext from "../context/state/StateContext"
import DataContext from "../context/Data/DataContext"
import AddToWishlist from "../components/Elements/AddToWishlist"
import { handleScrolltoRef } from "../utils"
import Skeleton from "../components/Skeleton"
import Container from "../components/Container"
import AuthenticationContext from "../context/authentication/AuthenticationContext"
import AccessDenied from "../components/AccessDenied"
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const ProductSingle = ({ location }) => {
  // console.log(serverData)
  const { state } = useContext(StateContext)
  const { currentUserData } = useContext(AuthenticationContext).authentication
  const [serverData, setServerData] = useState(null)
  const [forbidden, setForbidden] = useState(false)
  const goBack = () => {
    console.log("is back", window.PressedBack)
    window.PressedBack = true
  }
  useEffect(() => {
    window.addEventListener("popstate", goBack)
    return () => {
      window.removeEventListener("popstate", goBack)
    }
  }, [])

  useEffect(async () => {
    let array = location.pathname.split("/")
    if (array.length > 2) {
      let nid = array[2]
      let res = await getSingleProduct(state.token, parseInt(nid))
      if (res.status === 200) {
        res = await res.json()
        setServerData({ ...res, id: nid })
        trackVisits(state.token, {
          type: "product",
          product: nid,
          user: currentUserData.i,
        })
      } else if (res.status === 403) {
        setForbidden(true)
      } else {
        navigate("/404")
      }
    } else {
      navigate("/404")
    }
  }, [location])
  const product = serverData

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })
  const scrollRef = useRef()
  const { myBookmarksID } = useContext(DataContext).data

  if (serverData === false) return navigate("/404")

  if (forbidden) {
    return <AccessDenied />
  }
  return (
    <Layout
      metaTitle={!serverData ? "Product " : serverData.title}
      metaDesc={""}
    >
      <div className="bg-white">
        <div className="max-w-2xl mx-auto pt-[30px] pb-[60px] md:py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
          {/*<div className="mt-10">
            {" "}
            <RelatedProduct />
          </div>*/}
          {serverData !== null ? (
            <>
              {" "}
              <div className="lg:grid lg:grid-cols-8 lg:gap-x-8 lg:items-start">
                {/* Image gallery */}
                <div className="lg:col-span-5">
                  <ProductImageBox
                    images={serverData.img}
                    name={serverData.title}
                  />
                </div>
                {/* Product info */}
                <div className="mt-[30px] md:mt-10 md:px-4 sm:px-0 sm:mt-16 lg:mt-0 lg:col-span-3 lg:pl-8">
                  <div className="flex justify-between">
                    <h1 className="text-title-med-30">{product.title}</h1>
                    <AddToWishlist
                      title={product.title}
                      id={product.id}
                      name={"product"}
                      isListed={
                        myBookmarksID["product"]?.includes(product.id)
                          ? true
                          : false
                      }
                      sm
                      noText
                    />
                  </div>

                  <Separator className="mt-4 mb-4" />
                  <div className="">
                    <MetaBox
                      title={"Product Type"}
                      body={product.categories
                        .map(v => v.title)
                        .toLocaleString()}
                    />
                    <MetaBox
                      title={"Tags"}
                      body={product.tags.map(v => v.title).toLocaleString()}
                    />
                  </div>
                  <Separator className="mt-4 mb-4" />
                  <div className="flex items-center">
                    <Link to={`/brand/${product.brand_id}`} className="mr-auto">
                      <CircleAvatar
                        image={product.brand_logo}
                        text={product.brand_name}
                        alt={product.brand_name}
                        md
                        border
                      />
                    </Link>
                    {/*<MetaDelivery large />*/}
                  </div>
                  <Separator className="mt-4 mb-4" />
                  <div className="text-meta-med">{serverData.desc}</div>
                  <Separator className="mt-4 mb-4" />

                  {false && !isMobile && (
                    <div className="mt-3">
                      <h2 className="text-title-semibold-18">Top Assets</h2>
                      <ul role="list" className="mb-7">
                        {[...serverData.assets].splice(0, 3).map((item, k) => (
                          <Link to={`/asset/${item.id}`}>
                            <AssetsCard {...item} key={k} mini />
                            {k !== product.assets.length - 1 && (
                              <Separator className="mt-2 mb-2" />
                            )}
                          </Link>
                        ))}
                      </ul>
                      <Button
                        red
                        outline
                        full
                        onClick={() => handleScrolltoRef(scrollRef?.current)}
                      >
                        View more
                      </Button>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-[30px] md:mt-24" ref={scrollRef}>
                {serverData.assets && serverData.assets.length > 0 ? (
                  <ProductAssetListing
                    assets={serverData.assets}
                    title={"Assets"}
                    refP={scrollRef?.current}
                  />
                ) : (
                  "No Assets for this product"
                )}
              </div>
            </>
          ) : (
            <>
              {/*<div className="max-w-2xl mx-auto pt-[30px] pb-[60px] md:py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">*/}

              <div className="lg:grid lg:grid-cols-8 lg:gap-x-8 lg:items-start">
                {/* Image gallery */}
                <div className="lg:col-span-5">
                  <div className="flex">
                    <div className="w-[100px] h-[100px] mr-2">
                      <Skeleton
                        dark
                        width={"100px"}
                        height={"100px"}
                        rounded={"10px"}
                      />
                    </div>
                    <div className="w-[90%] ">
                      <Skeleton
                        dark
                        width={"80%"}
                        height={"400px"}
                        rounded={"10px"}
                      />
                    </div>
                  </div>
                </div>
                {/* Product info */}
                <div className="mt-[30px] md:mt-10 md:px-4 sm:px-0 sm:mt-16 lg:mt-0 lg:col-span-3 lg:pl-8">
                  <div className="flex justify-between">
                    <h1 className="text-title-med-30">
                      <Skeleton dark width={"400px"} height={"40px"} />
                    </h1>
                    <Skeleton dark width={"300px"} height={"40px"} />
                  </div>

                  <Separator className="mt-4 mb-4" />
                  <div className="">
                    <Skeleton dark width={"300px"} height={"40px"} />
                  </div>
                  <Separator className="mt-4 mb-4" />
                  <div className="flex items-center">
                    <div className="mr-auto">
                      <CircleAvatar md border />
                    </div>
                    {/*<MetaDelivery large />*/}
                  </div>
                  <Separator className="mt-4 mb-4" />
                  <div className="text-meta-med">
                    {" "}
                    <Skeleton dark width={"200px"} height={"10px"} />
                    <Skeleton dark width={"200px"} height={"10px"} />
                    <Skeleton dark width={"200px"} height={"10px"} />
                  </div>
                  <Separator className="mt-4 mb-4" />

                  <div className="mt-3">
                    <h2 className="text-title-semibold-18">
                      <Skeleton dark width={"200px"} height={"20px"} />
                    </h2>
                    <ul role="list" className="mb-7">
                      <Skeleton dark width={"200px"} height={"100px"} />
                    </ul>
                    <div className="w-6 h-6">
                      {" "}
                      <Skeleton dark width={"200px"} height={"20px"} />{" "}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </Layout>
  )
}
// export async function getServerData(context) {
//   // console.log(context)
//   let { nid } = context.pageContext
//   if (!nid) nid = Object.values(context.params)[0]

//   try {
//     const token = await sessToken()
//     const res = await getSingleProduct(token, parseInt(nid))
//     // console.log(res)
//     if (!res) {
//       return {
//         props: false,
//       }
//     }
//     return {
//       props: res,
//     }
//   } catch (error) {
//     return {
//       status: 404,
//       headers: {},
//       props: {},
//     }
//   }
// }
export default ProductSingle
