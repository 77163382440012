/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import { green } from "../Styles"
function MetaBox(props) {
  const{title, body} = props
  return (
    <div className="flex items-start text-left grid grid-cols-3 mb-4">
      <h2 className="text-meta-med col-span-1 !leading-[23px]">{title}</h2>
      <h2 className="text-reg-16 text-dark mr-6 col-span-2 !leading-[23px]">
        {body}
      </h2>
    </div>
  )
}

export default MetaBox
