/** @jsx jsx */
import { css, jsx } from "@emotion/react"
import React from "react"
import {
  // Fragment,
  useState,
  useEffect,
} from "react"
// import {
//   Dialog,
//   Disclosure,
//   Menu,
//   Popover,
//   Tab,
//   Transition,
// } from "@headlessui/react"
// import {
//   MenuIcon,
//   SearchIcon,
//   ShoppingBagIcon,
//   XIcon,
// } from "@heroicons/react/outline"
// import {
//   ChevronDownIcon,
//   FilterIcon,
//   MinusSmIcon,
//   PlusSmIcon,
//   ViewGridIcon,
// } from "@heroicons/react/solid"
import Separator from "./Elements/Separator"
import AssetsCard from "../components/Cards/AssestCard"
import Pagination from "./Pagination"
import FilterSelect from "./Elements/FilterSelect"
import ButtonFilter from "./Elements/ButtonFilter"
import { useMediaQuery } from "react-responsive"
import Slideout from "./Elements/Slideout"
import { formatDate, handleScrolltoRef } from "../utils"
// const sortOptions = [
//   { name: "Most Popular", href: "#", current: true },
//   { name: "Best Rating", href: "#", current: false },
//   { name: "Newest", href: "#", current: false },
//   { name: "Price: Low to High", href: "#", current: false },
//   { name: "Price: High to Low", href: "#", current: false },
// ]
const sortOptions = [
  { label: "Newest ", value: "created_d" },
  { label: "Oldest", value: "created" },
  { label: "Title (ascending)", value: "title" },
  { label: "Title (descending)", value: "title_d" },
  { label: "Recently Updated", value: "last_change" },
  { label: "Most Content ", value: "files" },
]
const viewOptions = [
  { label: "View 6", value: 6 },
  { label: "View 8", value: 8 },

  { label: "View 12", value: 12 },
]
function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}
function ProductAssetListing(props) {
  const { assets, title, column = false, showTitle } = props
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [list, setList] = useState([])
  const [sortOp, setSortOP] = useState(sortOptions[0].name)
  const [pageNumber, setPageNumber] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [total, setTotal] = useState(1)
  const [perPage, setPerPage] = useState(6)

  const isMobile = useMediaQuery({
    query: "(max-width: 768px)",
  })

  useEffect(async () => {
    let d = [...assets]
    let pages = Math.floor((assets.length + perPage - 1) / perPage)
    setTotalPage(pages)
    console.log(pages)
    let newPageData = d.splice(0, perPage)
    setList([...newPageData])
    let total = assets.length
    setTotal(assets.length)
  }, [])

  useEffect(async () => {
    let d = [...assets]

    let newPageData = d.splice(0, perPage)
    setList([...newPageData])
    let total = assets.length
    setTotal(assets.length)
    setList([
      ...assets.sort(
        (a, b) =>
          new Date(formatDate(a?.last_change)) -
          new Date(formatDate(b?.last_change))
      ),
    ])
    let pages = Math.floor((assets.length + perPage - 1) / perPage)
    setTotalPage(pages)
  }, [assets])

  const perPageChange = v => {
    let pages = Math.floor((assets.length + v - 1) / v)
    setTotalPage(pages)
    setPerPage(v)
    setPageNumber(1)
    let d = [...assets]
    // let count = 1 * perPage
    let newPageData = d.splice(0, v)
    setList([...newPageData])

    if (props.refP) handleScrolltoRef(props.refP)
  }
  // useEffect(() => {
  //   let pages = Math.ceil(total / perPage)
  //   setTotalPage(pages)
  // }, [total])
  const onPageChange = async v => {
    setPageNumber(v)
    let d = [...assets]
    let count = v * perPage
    console.log(perPage)
    let newPageData = d.splice(v !== 1 ? (v - 1) * perPage : 0, perPage)
    console.log(newPageData)
    setList([...newPageData])
    if (props.refP) handleScrolltoRef(props.refP)

    // setTotal(newPageData.total)
  }

  return (
    <>
      <h3 className="text-title-semibold-18">
        {showTitle ? (title ? title : `Assets (Media)`) : null}
      </h3>
      <div className="relative flex items-baseline justify-between pt-5">
        <div className="flex items-center justify-between w-full">
          {isMobile === true && (
            <ButtonFilter
              callback={() => {
                setMobileMenuOpen(!mobileMenuOpen)
              }}
              title="Filters"
            />
          )}
          {(!column || isMobile) && (
            <p className="text-reg-15 text-dark">
              Showing {pageNumber < totalPage ? perPage : assets.length} of{" "}
              {assets.length} assets
            </p>
          )}

          {!isMobile && (
            <div className={`flex ${column && "-ml-6"}`}>
              {!column && (
                <FilterSelect
                  options={viewOptions}
                  onChange={v => perPageChange(v)}
                />
              )}
              <div className="flex ml-6">
                <p class="text-reg-15 text-dark">Sort by:</p>
                <FilterSelect
                  options={sortOptions}
                  onChange={v => {
                    // console.log(v)
                    // setSortOP(v)
                    console.log(v, assets)
                    if (v == "last_change" || v == "created") {
                      setList([
                        ...assets.sort(
                          (a, b) =>
                            new Date(formatDate(b?.last_change)) -
                            new Date(formatDate(a?.last_change))
                        ),
                      ])
                    } else if (v == "created_d") {
                      setList([
                        ...assets.sort(
                          (a, b) =>
                            new Date(formatDate(a?.last_change)) -
                            new Date(formatDate(b?.last_change))
                        ),
                      ])
                    } else if (v.includes("title")) {
                      // console.log(assets.sort((a, b) => a[d] - b[d]))
                      if (v.includes("_d")) {
                        let d = v.replace("_d", "")
                        setList([
                          ...assets.sort((a, b) =>
                            b[d].toLowerCase().localeCompare(a[d].toLowerCase())
                          ),
                        ])
                      } else {
                        setList([
                          ...assets.sort((a, b) =>
                            a[v].toLowerCase().localeCompare(b[v].toLowerCase())
                          ),
                        ])
                      }
                    } else {
                      // console.log(assets.sort((a, b) => b[v] - a[v]))

                      setList([...assets.sort((a, b) => b[v] - a[v])])
                    }
                  }}
                  defaultValue={sortOptions[0]}
                  last
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <ul
        role="list"
        className={`${
          column ? "h-full" : "grid gap-x-[30px] gap-y-0 md:grid-cols-2"
        }`}
      >
        {column && !isMobile ? (
          <>
            {assets.map((item, k) => {
              return (
                <div>
                  <AssetsCard
                    assetData={item}
                    {...item}
                    key={k}
                    fromAssetGroupPage
                    type={item.type}
                    tags={assets.category}
                  />
                </div>
              )
            })}
          </>
        ) : (
          <>
            {list.map((item, k) => {
              let showSeparator = true

              if (isMobile && k === list.length - 1) {
                showSeparator = false
              } else {
                if (list.length % 2 /*odd number*/ && k === list.length - 1)
                  showSeparator = false
                if (
                  !(list.length % 2) /*odd number*/ &&
                  (k === list.length - 1 || k === list.length - 2)
                )
                  showSeparator = false
              }

              return (
                <div>
                  <AssetsCard
                    assetData={item}
                    /* @TODO: remove this superflous spread, favor assetData prop for additional nested required ops */ {...item}
                    key={k}
                    type={item.type}
                  />
                  {showSeparator === true && (
                    <Separator className="mt-0 mb-0" />
                  )}
                </div>
              )
            })}
          </>
        )}
      </ul>
      {(!column || isMobile) && (
        <Pagination
          notLink
          page={pageNumber}
          setPage={v => onPageChange(v)}
          totalPages={totalPage}
        />
      )}
      <Slideout
        title="Filter Assets"
        onClose={setMobileMenuOpen}
        open={mobileMenuOpen}
        setOpen={setMobileMenuOpen}
        // toggleSidebar={toggleSidebar}
        fromPage={false}
      >
        <FilterSelect
          options={viewOptions}
          customTitle={"View Options"}
          sidebar
          onChange={v => perPageChange(v.value)}
          defaultValue={viewOptions[0]}
        />
        <Separator className="my-[30px]" />
        <FilterSelect
          options={sortOptions}
          onChange={v => {
            // console.log(v)
            // setSortOP(v)
            if (v.includes("_d")) {
              let d = v.replace("_d", "")
              // console.log(assets.sort((a, b) => a[d] - b[d]))
              setList([...assets.sort((a, b) => a[d] - b[d])])
            } else {
              // console.log(assets.sort((a, b) => b[v] - a[v]))

              setList([...assets.sort((a, b) => b[v] - a[v])])
            }
          }}
          customTitle={"Sort Options"}
          sidebar
          defaultValue={sortOptions[0]}
        />
      </Slideout>
    </>
  )
}

export default ProductAssetListing
